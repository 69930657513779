/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const [loginStatus, userName] = validateToken()
    setIsLoggedIn(!!localStorage.getItem('token'))
    setCurrentUser(!!localStorage.getItem('username'))
    setIsLoading(false)
  }, [])

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    setIsLoggedIn(false)
    setCurrentUser('')
  }

  const validateToken = () => {
    const token = localStorage.getItem('token')
    if (token) {
      const decodedToken = jwtDecode(token)
      const currentDate = new Date()
      console.log([decodedToken.exp * 1000, currentDate.getTime()])
      console.log(decodedToken.exp * 1000 < currentDate.getTime())
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log('Token is expired')
        localStorage.removeItem('token')
        return [false, '']
        // Implement a redirect to login here, e.g., using React Router
      } else {
        console.log('Token is valid')
        return [true, decodedToken.username]
      }
    } else {
      return [false, '']
    }
  }

  const contextValue = {
    isLoggedIn,
    setIsLoggedIn,
    isLoading,
    logout,
    currentUser,
    setCurrentUser,
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
