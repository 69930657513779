import React, { Component, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './AuthCon'
import PrivateRoute from './PrivateRoute'
import { BrowserRouter as Router } from 'react-router-dom'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const EinzelAuftrag = React.lazy(() => import('./views/pages/EinzelAuftrag/EinzelAuftrag'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/Auftrag" name="Auftrag" element={<EinzelAuftrag />} />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="DKA"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </AuthProvider>
    )
  }
}

export default App
