/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useAuth } from './AuthCon'
import { Navigate } from 'react-router-dom'

function PrivateRoute({ children }) {
  const { isLoggedIn, isLoading } = useAuth()

  if (isLoading) {
    return null
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default PrivateRoute
